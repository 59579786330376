import { render } from 'react-dom';
import { configureAnalytics, initGtm } from '@lingoda/analytics';
import { startClock } from '@lingoda/clock';
import { initRequestWebProvider } from '@lingoda/http';
import { initErrorTracker } from '@lingoda/monitor';
import { goTo, history, teacherLogoutPath } from '@lingoda/router';
import Root from './Root';

initErrorTracker(history);
startClock();

window.addEventListener('load', () => {
    initGtm('teacherFrontendGoogleTagManagerId');
});

void configureAnalytics();

initRequestWebProvider({
    onUnauthorized: () => goTo(teacherLogoutPath()),
});

render(<Root history={history} />, document.getElementById('root'));
