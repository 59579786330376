import { alpha } from '../utils';
import { colorsPalette } from '../colorsPalette';

const darkBlueToPurple = `linear-gradient(128.47deg, ${colorsPalette.darkblueSolid[100]} 15.98%, ${colorsPalette.purpleSolid[100]} 102.18%);`;

const purpleToPrimary = `linear-gradient(260.96deg, ${colorsPalette.primarySolid[100]} -80.13%, ${colorsPalette.purpleSolid[100]} 100%);`;

const purpleToPrimaryAlpha = `linear-gradient(260.96deg, ${alpha(
    colorsPalette.primarySolid[100],
    0.1,
)} -80.13%, ${alpha(colorsPalette.purpleSolid[100], 0.1)} 100%);`;

const purpleToPrimaryFlattenedAlpha = `linear-gradient(260.96deg, ${colorsPalette.primarySolid[10]} -80.13%, ${colorsPalette.purpleSolid[10]} 100%);`;

const primaryToPurple = `linear-gradient(260.96deg, ${colorsPalette.purpleSolid[100]} 5.24%, ${colorsPalette.primarySolid[100]} 100%), linear-gradient(260.96deg, ${colorsPalette.primarySolid[100]} -80.13%, ${colorsPalette.purpleSolid[100]} 100%);`;

const primaryToPurpleAlpha = `linear-gradient(135deg, ${alpha(
    colorsPalette.primarySolid[100],
    0.1,
)} 0%, ${alpha(colorsPalette.purpleSolid[100], 0.1)} 100%);`;

const darkPurpleToPrimaryDark = `linear-gradient(260.96deg, ${colorsPalette.primaryCustom.dark} -80.13%, ${colorsPalette.purpleCustom.dark} 100%);`;

const negativeToPrimary = `linear-gradient(90deg, ${colorsPalette.negativeSolid[100]} 0%, ${colorsPalette.purpleSolid[100]} 50.8%, ${colorsPalette.primarySolid[100]} 100%);`;

const primaryToNegative = `linear-gradient(${colorsPalette.primarySolid[100]} 0%, ${colorsPalette.purpleSolid[100]} 50.8%, ${colorsPalette.negativeSolid[100]} 100%);`;

const primaryToPink = `linear-gradient(270deg, ${colorsPalette.pinkSolid[100]} 0%, ${colorsPalette.primarySolid[100]} 100%);`;

const pinkToPrimary = `linear-gradient(270deg, ${colorsPalette.primarySolid[100]} 0%, ${colorsPalette.pinkSolid[100]} 100%);`;

const peachGradient = `linear-gradient(270deg, ${colorsPalette.peachCustom.light} 0.06%, ${colorsPalette.peachCustom.dark} 99.91%);`;

const pinkToPrimaryCustom = `linear-gradient(to left, #ADBBFF, #FF99E6)`;

const whiteToBlue = `linear-gradient(90deg, ${colorsPalette.whiteSolid[100]} , #D6DDFF)`;

const goldLingobites = `linear-gradient(180deg, ${colorsPalette.goldCustom.dark} 7.74%, ${colorsPalette.goldCustom.light} 89.27%)`;

const grayToTransparent = `linear-gradient(180deg, ${colorsPalette.blackAlpha[12]} 0%, transparent 100%)`;

const lilaGradient = `linear-gradient(to right, #A1B0FF, #C999EB);`;

export const gradients = {
    darkBlueToPurple,
    darkPurpleToPrimaryDark,
    negativeToPrimary,
    peachGradient,
    pinkToPrimary,
    pinkToPrimaryCustom,
    primaryToPink,
    primaryToPurple,
    primaryToPurpleAlpha,
    purpleToPrimary,
    purpleToPrimaryAlpha,
    purpleToPrimaryFlattenedAlpha,
    primaryToNegative,
    whiteToBlue,
    goldLingobites,
    grayToTransparent,
    lilaGradient,
} as const;
